.about-landing {
  position: relative;
  font-family: "Outfit", sans-serif;
  .desc {
    margin-bottom: 5rem;
  }
  .landing-shape {
    bottom: -5rem;
    right: 0rem;
  }
  &.arabic {
    direction: rtl;
    .landing-shape {
      right: initial;
      left: 3rem;
    }
  }
}

// Responsive

@media screen and (max-width: 768px) {
  .about-landing {
    .landing-shape {
      bottom: 0rem;
      right: -10rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .about-landing {
    .desc {
      font-size: 1.1rem;
      max-width: 100%;
    }
  }
}
