footer {
  background-image: url("../../Assets/Images/footerbg.webp");
  background-size: cover;
  color: white;
  padding: 5rem 5rem 0;
  font-family: "Inter";
  > div:first-child {
    padding-bottom: 3rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    img{
      width: 100%;
    }
  }
  .links-container{
    display: flex;
    gap: 2rem;
    .links{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat((4, minmax(0, 1fr)));
      row-gap: 1rem;
      column-gap: 1.5rem;
      list-style: none;
      .links-heading{
        grid-column-start: 1;
        grid-column-end: 3;
      }
      li{
        max-width: 20ch;
        display: flex;
        align-items: center;
        height: fit-content;
        .link{
          font-size: .9rem;
          text-decoration: none;
          color: rgba(255, 255, 255, 60%);
        }
      }
    }
  }
   
  .license {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    direction: ltr;
    .icons {
      display: flex;
      justify-self: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.3);
        font-size: 0.8rem;
        &:not(:last-child) {
          margin-right: 0.8rem;
        }
        a{
          color: white;
          
        }
      }
    }
    .copyright{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;

    }
  }
  &.arabic{
    direction: rtl;
    .info{
      .links-container{
        .links{
          direction: ltr;
        }
      }
    }
  }
}

// Responsive:

@media screen and (max-width: 992px) {
  footer {
    padding: 5rem 5rem 0;
    > div:first-child {
      border-bottom: none;
    }
    .info {
      flex-direction: column;
      .logo {
        margin-left: 0rem;
        margin-bottom: 4rem;
      }
      .contact-info {
        display: none;
      }
    .links-container{
      flex-direction: column;
    }
    }
    .license {
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .icons {
        .icon {
          &:not(:last-child) {
          }
        }
      }
      .copyright{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        // 
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
   &.arabic{
    .info{
      
    }
   }
  }
}

@media screen and (max-width: 576px) {
  footer {
    padding: 5rem 3rem 0;

    .info {
      .links {
        a {
          &:first-child {
            margin-right: 0rem;
          }
        }
      }
    }
    &.arabic{
      .info{
       
      }
     }
  }
}
