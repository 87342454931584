.home-landing {
  // background-image: url("../../../../Assets/Images/landingbg.webp");
  background-size: cover;
  padding: 4rem;
  // height: 546px;
  height: 100vh;
  font-family: "Outfit", sans-serif;
  // color: #1b3884;
  color: white;
  .landing-heading {
    font-size: 4rem;
    font-weight: 700;
    margin: 2rem 0 3rem;
    opacity: 0;
    animation: fadeIn 2s 0.5s forwards ease-out;
  }
  .comp-desc {
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    margin-bottom: 3rem;
    opacity: 0;
    animation: fadeIn 2s 1s forwards ease-out;
  }
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
  }
  video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -10;
  }
  .cta-btn {
    position: relative;
    height: 65px;
    width: 276px;
    font-size: 2rem;
    font-weight: 400;
    color: #0b193c;
    background-color: white;
    border-radius: 1rem;
    border: none;
    opacity: 0;
    animation: fadeIn 2s 1.5s forwards ease-out;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 0%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgb(238, 238, 238);
      border-radius: 1rem;
      transition-duration: 0.1s;
      transition-timing-function: ease-out;
      z-index: -1;
    }
    &:hover::after {
      width: 100%;
    }
  }
}

// Keyframes :

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Responsive queries:

@media screen and (max-width: 992px) {
  .home-landing {
    padding: 0 2rem;
    text-align: center;
    .landing-heading {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    .comp-desc {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .home-landing {
    padding: 0 1rem;
    text-align: center;
    .landing-heading {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    .comp-desc {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
    .cta-btn {
      height: 65px;
      width: 276px;
      font-size: 2rem;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 576px) {
  .home-landing {
    padding: 0 1rem;
    text-align: center;
    .landing-heading {
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
      font-weight: 900;
      letter-spacing: 0.1rem;
    }
    .comp-desc {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
    .cta-btn {
      height: 65px;
      width: 200px;
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 380px) {
  .home-landing {
    .landing-heading {
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
      font-weight: 900;
      letter-spacing: 0.1rem;
    }
    .comp-desc {
      font-size: 1rem;
    }
    .cta-btn {
      height: 50px;
      width: 150px;
      font-size: 1.3rem;
      font-weight: 400;
    }
  }
}
