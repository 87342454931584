





// Responsive

@media screen and (max-width:768px) {
  .heading{ 
    // 
    display: flex;
    justify-content: center;
    background: none;
    -webkit-text-fill-color: #1B3884;
    font-size: 2rem;
    svg{
      // 
      margin-right: 1rem;
    }
  }
}