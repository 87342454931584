.projects-landing{
  .landing-shape{
    bottom: -11rem;
    right: 1rem;
  }
}


// Responsive 
@media screen and (max-width: 768px) {

  .projects-landing{
    .landing-shape{
      right: -15rem;
  
    }
  }
}