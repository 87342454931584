.notification{
  position: fixed;
  top: 3rem;
  right: 3rem;
  background-color: white;
  color: #25D366;
  border-left: .5rem solid #25D366;
  color: white;
  z-index: 100000;
  animation: fadeInRight .5s ease-out forwards ;
  p, svg{
    color: #25D366;
    margin: 0;
    font-weight: bold;
  }
}

// Animations
@keyframes fadeInRight{
  from{
    transform: translateX(200px);
    opacity: 0;
  }
  to{
    transform: none;
    opacity: 1;
  }
}