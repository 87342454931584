.vision{
  padding: 6.5rem 4rem;
  font-family: "Outfit", sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  img{
    margin-right: 2rem;
  }
  .heading{
    margin-bottom: 2.5rem;
  }
  .text{
    font-size: 1.150rem;
    font-weight: 400;
    max-width: 60ch;
    line-height: 2rem;
  }
}


// Responsive:

@media screen and (max-width: 992px) {
  .vision{
    padding: 6.5rem 4rem;
    font-family: "Outfit", sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // 
    flex-direction: column-reverse;
    align-items: center;
    padding: 2.5rem 3rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    img{
      max-width: 100%;
      margin-right: 0;
    }
    .heading{
      margin-bottom: 2.5rem;
      // 
      display: flex;
      justify-content: center;
      font-size: 2rem;
    }
    .text{
      font-size: 1.150rem;
      font-weight: 400;
      width: 60ch;
      line-height: 2rem;
      // 
      text-align: center;
      margin-bottom: 5rem;
      width: 100%;
      font-size: 1rem;
    }
  }
}



@media screen and (max-width: 768px) {
  .vision{
    padding: 6.5rem 4rem;
    font-family: "Outfit", sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // 
    flex-direction: column-reverse;
    align-items: center;
    padding: 2.5rem 3rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    // 
    padding: 2.5rem 1.5rem;
    
    img{
      max-width: 100%;
      margin-right: 0;
    }
    .heading{
      margin-bottom: 2.5rem;
      // 
      display: flex;
      justify-content: center;
      font-size: 2rem;
    }
    .text{
      font-size: 1.150rem;
      font-weight: 400;
      width: 60ch;
      line-height: 2rem;
      // 
      text-align: center;
      margin-bottom: 5rem;
      width: 100%;
      font-size: 1rem;
    }
  }
}