.solutions-landing{
  .landing-shape{
    bottom: -5rem;
  }
}


@media (max-width: 768px) {
  .solutions-landing{
    .landing-shape{
      right: -11rem;
      bottom: 2rem;
    }
  }
}