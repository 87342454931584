.news-section{
  padding: 5rem;
  .news-container{
    border-radius: 1rem;
    overflow: hidden;
  }
}

// responsive

@media screen and (max-width: 992px) {
  .news-section{
    padding: 2rem 0;
    
  }
}