.contact-landing{
  .landing-shape{
    bottom: -19rem;
    right: -11rem;
  }
}


// Responsive

@media screen and (max-width: 768px) {
  .contact-landing{
    .landing-shape{
      right: -20rem;

    }
  }
}
