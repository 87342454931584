.solutions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Outfit", sans-serif;
  padding: 4rem 0 4rem 4rem;
  height: 664px;
  overflow-x: hidden;

  .section-info {
    width: 70%;
    .heading {
      font-size: 2.5rem;
    }
    .section-desc {
      position: relative;
      font-size: 1.25rem;
      font-weight: 400;
      width: fit-content;
      margin-bottom: 4rem;
    }
  }

  .slider-btns {
    margin-left: 5rem;
    .slider-btn {
      padding: 1rem;
      border: none;
      border-radius: 1rem;
      box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
      // pointer-events: none;
      cursor: pointer;
      &:nth-child(1) {
        border: 1px solid rgba(27, 56, 132, 0.4);
        margin-right: 2.5rem;
      }
      &:nth-child(2) {
        background: linear-gradient(93.29deg, #1b3884 -30.03%, #5dc2e9 117.37%);
      }
    }
  }

  .solutions-slider {
    position: relative;
    overflow: hidden;

    transform: translateY(5rem);
    .bg-shape {
      position: relative;
      width: 100%;
      height: 185px;
      background-color: #0b193c;
      top: -3rem;
      // left: -3rem;
      z-index: -1;
    }
    .solutions-container {
      position: relative;
      left: 0rem;
      transform: translateY(-6rem);
      transition-duration: 1s;
      pointer-events: none;
      .solution-box {
        position: relative;
        margin-right: 1.6rem;
        &:first-child{margin-left: 0.8rem;}
        &.arabic{
          margin-left: 1.6rem;
          margin-right: 0;
          &:first-child{margin-right: 0.8rem;}
        }
        img {
          width: 224px;
          z-index: -1;
          border-radius: 10px;
        }
        .solution-name {
          position: absolute;
          text-align: center;
          bottom: 1rem;
          left: 50%;
          transform: translateX(-50%);
          color: white;
          font-size: 1.25rem;
          font-weight: 500;
          width: 100%;
          z-index: 2;
        }
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0rem;
          left: 0;
          background: linear-gradient(
            180deg,
            rgba(27, 56, 132, 0) 54.44%,
            #1b3884 89.63%
          );
          border-radius: 10px;
        }
        .show-btn {
          position: absolute;
          padding: 1rem;
          border: none;
          border-radius: 1rem;
          left: 50%;
          transform: translateX(-50%);
          bottom: -28px;
          z-index: 2;
          pointer-events: all;
        }
      }
    }
  }
  &.arabic{
    padding: 4rem 4rem 4rem 0;
    flex-direction: row-reverse;
    .slider-btns{
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .solutions-slider{
      .solutions-container{
        flex-direction: row-reverse;
      }
    }
  }
}


// Responsive:



@media only screen and (max-width: 992px) {
  .solutions {
    padding: 4rem 0 4rem 2rem;
    .section-info {
      .heading {
        font-size: 2rem;
      }
      .section-desc {
        font-size: 1rem;
      }
    }
    .slider-btns{
      margin-left: 2.5rem;
    }
  }
}


@media only screen and (max-width: 768px) {
  .solutions {
    height: initial;
    text-align: center;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    padding: 4rem !important;
    .section-info {
      width: initial;
      .heading {
        font-size: 2.5rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 2rem;
        svg {
          margin-right: 0;
        }
      }
      .section-desc {
        margin-left: 0rem;
        margin-bottom: 4rem;
      }
    }
    .slider-btns {
      display: none !important;
    }
    .solutions-slider {
      position: relative;
      transform: none;
      .bg-shape {
        display: none;
      }
      .solutions-container {
        position: relative;
        left: 0rem;
        transform: none;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        .solution-box {
          position: relative;
          margin: 0 0 5rem 0 !important;
          display: flex;
          align-items: center;
          &:first-child{margin-left: 0;}
          img {
          }
          .solution-name {
          }
          &::after {
          }
          .show-btn {
          }
        }
      }
    }
  }
}