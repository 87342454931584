.products-preview{
  padding: 7rem 3rem 1.5rem 3.25rem;
  overflow: hidden;
  margin-bottom: 1rem;
  .products-slider{
    position: relative;
    opacity: 0;
    animation: fadeInRight 1s  ease-out forwards;
    .product{
      position: relative;
      margin-bottom: 1rem;
      &:not(:last-child){
        margin-right: 2.25rem;
      }
      img{
      border-radius: 1rem;
      width: 100%;
      }
      .info{
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        padding: 1.4rem;
        text-align: center;
        color: white;
        background-color: #0F121E;
        font-weight: 700;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        .product-name{
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
        .download{
          font-size: 1.125rem;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

// Responsive
@media screen and (max-width: 768px) {
  .products-preview{
    padding: 7rem 0 1.5rem 3.25rem;
    overflow: hidden;
    // 
    padding: 2rem 0;
    overflow: visible;
    .products-slider{
      position: relative;
      opacity: 0;
      animation: fadeInRight 1s  ease-out forwards;
      pointer-events: none;
      // 
      pointer-events: all;
      overflow: scroll;
      scrollbar-width: none ;
      &::-webkit-scrollbar{
        display: none;
      }
      .product{
        position: relative;
        &:first-child{
          margin-left: 2rem;
        }
        &:not(:last-child){
          margin-right: 2.25rem;
          // 
          margin-right: 0rem;
        }
        img{
        border-radius: 1rem;
        // width: 100%;
        width: 70vw;
        }
        .info{
          position: absolute;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          padding: 1.4rem;
          text-align: center;
          color: white;
          background-color: #0F121E;
          font-weight: 700;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          .product-name{
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }
          .download{
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}


// Animation
@keyframes fadeInRight {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to{
    transform: translateX(0);
    opacity: 1;
  }
}