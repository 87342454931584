.solutions-section {
  padding: 5.5rem 3.75rem;
  font-family: "Outfit", sans-serif;
  .section-desc {
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 4.4rem;
  }
  .solutions-container {
    .solutions-group {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 6.5rem;
      .solution-box {
        position: relative;
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
        box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        transition: all 0.3s;
        border-radius: 2rem;
        
        .svgs {
          position: relative;
          z-index: 2;
          margin-bottom: 3.5rem;
          img:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition-duration: 0.5s;
          }
        }
        .solution-name {
          text-align: center;
          width: fit-content;
          font-weight: 700;
          font-size: 2rem;
          background: linear-gradient(180deg, #0b193c 0%, #1b3884 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.5s;
          z-index: 2;
          overflow: hidden;
        }
        .hover-box {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(230.75deg, #0b2c82 0%, #0a1940 102.06%);
          z-index: 1;
          opacity: 0;
          transition-duration: 0.5s;
          border-radius: 2rem;
        }
        &:not(:last-child) {
          // margin-right: 6.5rem;
        }
        &:hover,
        &.active {
          .solution-name {
            background: white;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .hover-box {
            opacity: 1;
          }
          .svgs {
            img:nth-child(2) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 768px) {
  .solutions-section {
    // padding: 5.5rem 3.75rem;
    padding: 5.5rem 0rem;
    // font-family: "Outfit", sans-serif;
    .heading{
      display: flex;
      justify-content: center;
      font-weight: 700;
      margin-bottom: 1.5rem;
      
    }
    .section-desc {
      // font-size: 1.5rem;
      // font-weight: 900;
      // margin-bottom: 4.4rem;
      text-align: center;
      font-weight: 400;
      font-size: 1rem;
      padding: 1rem;
    }
    .solutions-container {
      padding: 0 3.2rem;
      // 
      padding: 0 2rem;
      .solutions-group {
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        // gap: 6.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .solution-box {
          // position: relative;
          // padding: 3rem 1rem;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          // background-color: #f9f9f9;
          // box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.05);
          // cursor: pointer;
          // transition: all 0.3s;
          // border-radius: 2rem;
          width: 100%;
          height: 400px;
          .svgs {
            position: relative;
            z-index: 2;
            margin-bottom: 3.5rem;
            img:nth-child(2) {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              transition-duration: 0.5s;
            }
          }
          .solution-name {
            text-align: center;
            width: fit-content;
            font-weight: 700;
            font-size: 2rem;
            background: linear-gradient(180deg, #0b193c 0%, #1b3884 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all 0.5s;
            z-index: 2;
            overflow: hidden;
          }
          .hover-box {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(230.75deg, #0b2c82 0%, #0a1940 102.06%);
            z-index: 1;
            opacity: 0;
            transition-duration: 0.5s;
            border-radius: 2rem;
          }
          &:not(:last-child) {
            // margin-right: 6.5rem;
          }
          &:hover,
          &.active {
            .solution-name {
              background: white;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .hover-box {
              opacity: 1;
            }
            .svgs {
              img:nth-child(2) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}