.team{
  padding: 6.5rem 4rem;
  font-family: "Outfit", sans-serif;
  background-color: #F4F4F4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  .heading{
    margin-bottom: 2.5rem;
  }
  .text{
    font-size: 1.150rem;
    font-weight: 400;
    max-width: 50ch;
    line-height: 2rem;
    margin-right: 2rem;
    svg{
      display: none;
    }
  }

}



// Responsive

@media screen and (max-width: 992px) {
  .team{
    padding: 6.5rem 4rem;
    font-family: "Outfit", sans-serif;
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      // 
      display: none;
    }
    .heading{
      margin-bottom: 2.5rem;
      // 
      display: flex;
      justify-content: center;
    }
    .text{
      font-size: 1.150rem;
      font-weight: 400;
      max-width: 100%;
      line-height: 2rem;
      // 
      position: relative;
      background-color: white;
      padding: 4.5rem 3rem;
      // font-size: 1rem;
      font-weight: 700;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      margin-right: 0;
      svg{
        // 
        display: block;
        position: absolute;
        &:first-of-type{
          top: -7rem;
          left: -10rem;
        }
        &:last-of-type{
          bottom: -7rem;
          right: -6rem;
        }
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .team{
    padding: 6.5rem 4rem;
    font-family: "Outfit", sans-serif;
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // 
    padding: 6.5rem 2rem;
    img{
      // 
      display: none;
    }
    .heading{
      margin-bottom: 2.5rem;
      // 
      display: flex;
      justify-content: center;
    }
    .text{
      font-size: 1.150rem;
      font-weight: 400;
      max-width: 100%;
      line-height: 2rem;
      // 
      position: relative;
      background-color: white;
      padding: 4.5rem 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      svg{
        // 
        display: block;
        position: absolute;
        &:first-of-type{
          top: -7rem;
          left: -10rem;
        }
        &:last-of-type{
          bottom: -7rem;
          right: -6rem;
        }
      }
    }
  }
}