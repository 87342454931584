.product-page{
  font-family: 'Outfit';
  .big-image{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 332px;
    margin: 0rem 0 4rem 0;
    overflow: hidden;
    img{
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      filter: blur(8px);
    }
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0B193C7D;
      
    }
    .info{
      color: white;
      text-align: center;
      z-index: 1;
      .product-name{
        font-size: 2.5rem;
        font-weight: 800;
        margin-bottom: 2rem;
      }
      .product-desc{
        font-size: 1.5rem;
        max-width: 40ch;
      }
    }
  }
  .sub-products-section{
    padding: 0 4rem;
    overflow: hidden;
    .sub-product-slider{
      display: flex;
      gap: 8rem;
      align-items: flex-start;
      text-align: center;
      margin-bottom: 8rem;
      > .image-container{
        position: relative;
        height: 100%;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
        img{
          height: 100%;
          border-radius: 1.5rem ;
        }
        .sub-product-info{
          position: absolute;
          width: 100%;
          padding: 2rem;
          color: white;
          background-color: #0F121E;
          font-weight: 700;
          font-size: 1.5rem;
          border-radius: 0 0 1.5rem 1.5rem;
          bottom: 0;
        }
      }
      .fragments-slider{
        max-width: 100%;
        width: 100%;
        .fragment{
          width: 314px;
          .image-container{
            position: relative;
            text-align: center;
            margin-bottom: 1rem;
            border-radius: 10px;
            overflow: hidden;
            img{
              // width: 314px;
              // height: 100%;
              width: 100%;
            }
            .download {
              position: absolute;
              bottom: 1rem;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
              color: white;
              width: 100%;
              .download-btn{
                margin: 0 auto;
                width: 4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                color: #0B193C;
                border-radius: .6rem;
                font-size: 2rem;
                margin-bottom: 1.4rem;
              }
              p{
                font-size: 1.1rem;
                font-weight: 700;
                width: 100%;
              }
            }
            &::after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(to top, #030e29,#030e29c5, transparent);
            }
          }
          .frag-info{
            max-width: 100%;
            h4{
              color: #0B193C;
              font-size: 2rem;
              font-weight: 700;
              margin-bottom: 1.5rem;
            }
            p{
              color: black;
              font-size: 1.3rem;
              font-weight: 700;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}


.swiper {
  padding-bottom: 4rem !important;
}

.swiper-button-next{
  top: initial !important;
  bottom: .2rem;
  right: initial !important;
  transform: translateX(6rem);
  border: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  color: white;
}

.swiper-button-prev {
  top: initial !important;
  bottom: .2rem !important;
  right: initial !important;
  left: 1rem !important  ;
  border: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  padding: 2rem
}


// Responsive
@media screen and (max-width: 991px) {
  .product-page{
    .big-image{
      img{
      }
      &::after{
      }
      .info{
        .product-name{
        }
        .product-desc{
        }
      }
    }
    .sub-products-section{
      padding: 0 4rem;
      overflow: hidden;
      .sub-product-slider{
        display: flex;
        gap: 8rem;
        align-items: flex-start;
        text-align: center;
        margin-bottom: 8rem;
        // 
        flex-direction: column;
        align-items: center;
        > .image-container{
          position: relative;
          height: 100%;
          box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
          img{
            height: 100%;
            border-radius: 1.5rem ;
          }
          .sub-product-info{
            position: absolute;
            width: 100%;
            padding: 2rem;
            color: white;
            background-color: #0F121E;
            font-weight: 700;
            font-size: 1.5rem;
            border-radius: 0 0 1.5rem 1.5rem;
            bottom: 0;
          }
        }
        .fragments-slider{
          max-width: 100%;
          width: 100%;
          .fragment{
            width: 314px;
            .image-container{
              position: relative;
              text-align: center;
              margin-bottom: 1rem;
              border-radius: 10px;
              overflow: hidden;
              img{
                // width: 314px;
                // height: 100%;
                width: 100%;
              }
              .download {
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                color: white;
                width: 100%;
                .download-btn{
                  margin: 0 auto;
                  width: 4rem;
                  height: 4rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: white;
                  color: #0B193C;
                  border-radius: .6rem;
                  font-size: 2rem;
                  margin-bottom: 1.4rem;
                }
                p{
                  font-size: 1.1rem;
                  font-weight: 700;
                  width: 100%;
                }
              }
              &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to top, #030e29,#030e29c5, transparent);
              }
            }
            .frag-info{
              max-width: 100%;
              h4{
                color: #0B193C;
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
              }
              p{
                color: black;
                font-size: 1.3rem;
                font-weight: 700;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 576px) {
  .product-page{
    font-family: 'Outfit';
    .big-image{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 332px;
      margin: 0rem 0 4rem 0;
      overflow: hidden;
      // 
      padding:  0 2rem;
      img{
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        filter: blur(8px);
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0B193C7D;
        
      }
      .info{
        color: white;
        text-align: center;
        z-index: 1;
        .product-name{
          font-size: 2.5rem;
          font-weight: 800;
          margin-bottom: 2rem;
          // 
          font-size: 1.8rem;
          margin-bottom: 1rem;
        }
        .product-desc{
          font-size: 1.5rem;
          max-width: 40ch;
          // 
          font-size: 1.3rem;
        }
      }
    }
    .sub-products-section{
      padding: 0 4rem;
      overflow: hidden;
      // 
      padding: 0 2.5rem;
      .sub-product-slider{
        display: flex;
        gap: 8rem;
        align-items: flex-start;
        text-align: center;
        margin-bottom: 8rem;
        // 
        align-items: center;
        flex-direction: column;
        gap: 4rem;
        > .image-container{
          position: relative;
          height: 100%;
          box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
          // 
          width: 100%;
          img{
            height: 100%;
            border-radius: 1.5rem ;
            // 
            width: 100%;
          }
          .sub-product-info{
            position: absolute;
            width: 100%;
            padding: 2rem;
            color: white;
            background-color: #0F121E;
            font-weight: 700;
            font-size: 1.5rem;
            border-radius: 0 0 1.5rem 1.5rem;
            bottom: 0;
            // 
          }
        }
        .fragments-slider{
          max-width: 100%;
          width: 100%;
          .fragment{
            width: 314px;
            // 
            width: 206px;
            .image-container{
              position: relative;
              text-align: center;
              margin-bottom: 1rem;
              border-radius: 10px;
              overflow: hidden;
              img{
                // width: 314px;
                // height: 100%;
                width: 100%;
                
              }
              .download {
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                color: white;
                width: 100%;
                .download-btn{
                  margin: 0 auto;
                  width: 4rem;
                  height: 4rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: white;
                  color: #0B193C;
                  border-radius: .6rem;
                  font-size: 2rem;
                  margin-bottom: 1.4rem;
                }
                p{
                  font-size: 1.1rem;
                  font-weight: 700;
                  width: 100%;
                }
              }
              &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to top, #030e29,#030e29c5, transparent);
              }
            }
            .frag-info{
              max-width: 100%;
              h4{
                color: #0B193C;
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
              }
              p{
                color: black;
                font-size: 1.3rem;
                font-weight: 700;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  
  .swiper {
    padding-bottom: 4rem !important;
  }
  
  .swiper-button-next{
    top: initial !important;
    bottom: .2rem;
    right: initial !important;
    transform: translateX(6rem);
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    color: white;
  }
  
  .swiper-button-prev {
    top: initial !important;
    bottom: .2rem !important;
    right: initial !important;
    left: 1rem !important  ;
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  
    padding: 2rem
  }
  
}