.products-landing{
  .landing-shape{
    right: 9rem;
    bottom: 0rem;
  }
}



// Responsive 

@media screen and (max-width: 768px) {
  .products-landing{
    text-align: center;
    .landing-shape{
      right: -5rem;
      bottom: 0rem;
    }
  }
}