.contact-info {
  padding: 7.5rem 3.5rem;
  text-align: center;
  .map {
    //
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;

    iframe {
      border-radius: 1rem;
      //
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  .contact-container {
    padding: 0 5rem;
    .contact-form {
      padding: 5rem 2rem;
      margin-right: 5rem;
      border-radius: 1rem;
      background-color: white;
      box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.15);
      transform: translateY(-7rem);
      .form-header {
        font-size: 1.5rem;
        color: #07074e;
        margin-bottom: 4rem;
        font-weight: 700;
      }
    }
    .info {
      width: 100%;
      .info-header {
        padding: 2rem;
        font-size: 2rem;
        font-weight: 700;
        color: #07074e;
      }
      .contact-details {
        li {
          display: flex;
          // align-items: center;
          align-items: flex-start;
          margin-bottom: 3.5rem;
          .info-title {
            margin-right: 3rem;
            transform: translateY(-1rem);
            width: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
              margin-bottom: 1rem;
            }
            .info-name {
              font-size: 1.5rem;
            }
          }
          > a,
          > p {
            font-size: 1.3rem;
            width: 100%;
            padding: 0 0 3rem 3rem;
            border-bottom: 2px solid #07074e;
            text-decoration: none;
            color: #07074e;
          }
        }
      }
      .social-media-links {
        font-size: 1.5rem;
        font-weight: 700;
        color: #07074e;
        margin-bottom: 1.5rem;
        .social-media-title {
          margin-bottom: 1.5rem;
        }
        .links {
          display: flex;
          align-items: center;
          justify-content: space-between;
          a {
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #1b3884;
            border-radius: 50%;
            svg {
              color: #1b3884;
            }
          }
        }
      }
    }
  }
  &.arabic {
    .contact-container {
      flex-direction: row-reverse;
      .contact-form {
        margin-right: 0;
        margin-left: 5rem;
      }
      .info {
        .info-header {
          display: flex;
          justify-content: flex-end;
        }
        .contact-details {
          direction: rtl;
          li {
            .info-title {
              direction: rtl;
              margin-right: 0;
              margin-left: 3rem;
            }
          }
        }
        .social-media-links {
          text-align: end;
        }
      }
    }
  }
}

// Responsive

@media screen and (max-width: 991px) {
  .contact-info {
    padding: 7.5rem 3.5rem;
    text-align: center;
    //
    padding: 2rem 1rem;
    .map {
      //
      position: relative;
      padding-bottom: 75%;
      height: 533px;
      overflow: hidden;

      iframe {
        border-radius: 1rem;
        //
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
    .contact-container {
      padding: 0 5rem;
      //
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      .contact-form {
        padding: 5rem 2rem;
        margin-right: 5rem;
        border-radius: 1rem;
        background-color: white;
        box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.15);
        transform: translateY(-7rem);
        //
        padding: 5rem 2rem 0rem;
        margin-right: 0;
        width: 100%;
        transform: translateY(-2rem);
        .form-header {
          font-size: 1.5rem;
          color: #07074e;
          margin-bottom: 4rem;
          font-weight: 700;
        }
      }
      .info {
        width: 100%;
        .info-header {
          padding: 2rem;
          font-size: 2rem;
          font-weight: 700;
          color: #07074e;
          //
          font-size: 2rem;
          padding-left: 0;
        }
        .contact-details {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 3.5rem;
            .info-title {
              margin-right: 3rem;
              transform: translateY(-1rem);
              width: 3rem;
              display: flex;
              flex-direction: column;
              align-items: center;

              svg {
                margin-bottom: 1rem;
              }
              .info-name {
                font-size: 1.5rem;
                color: #07074e;
                //
                font-size: 1rem;
                font-weight: 700;
              }
            }
            > p,
            > a {
              font-size: 1.3rem;
              width: 100%;
              padding: 0 0 3rem 0rem;
              border-bottom: 2px solid #07074e;
              //
              padding: 0 0 3rem 0;
              font-size: 1.1rem;
            }
          }
        }
        .social-media-links {
          font-size: 1.5rem;
          font-weight: 700;
          color: #07074e;
          margin-bottom: 1.5rem;
          //
          display: none;
          .social-media-title {
            margin-bottom: 1.5rem;
          }
          .links {
            display: flex;
            align-items: center;
            justify-content: space-between;
            a {
              width: 4rem;
              height: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #1b3884;
              border-radius: 50%;
              svg {
                color: #1b3884;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-info {
    .contact-container {
      .info {
        .contact-details {
          li.email a {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
