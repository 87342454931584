.news{
  position: relative;
  font-family: 'Outfit';
  overflow: hidden;
  border-radius:1rem ;
  width: 100%;
  margin-bottom: 2rem;
  > * {
    transition-duration: .5s;
  }
  img {
    max-width: 100%;
    width: 100%;
    filter: grayscale(1);
  }
  .title{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: .2rem;
    color: white;
    filter: none;

  }
  .news-info{
    position: absolute;
    width: 100%;
    padding: 1rem 2rem;
    background: linear-gradient(230.75deg, #2c55be 0%, #2b4997 102.06%);
    bottom: -50%;
    .news-title{
      font-size: 2rem;
      font-weight: 700;
      color: white;
      margin: 0;
    }
    .news-desc{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.5rem;
      color: white;
    }
    .see-more{
      padding: .5rem 1rem;
      color: white;
      background: transparent;
      border: 2px solid white;
      border-radius: 2rem;
    }
  }
  &:hover{
    .title{
      opacity: 0;
      visibility: hidden;
    }
    img{
      filter: none;
    }
    .news-info{
      bottom: 0;
    }
  }
}


// Responsive 

@media screen and (max-width: 992px) {
  .news{
    border-radius:.5rem ;
    > * {
      transition-duration: .5s;
    }
    img {
      max-width: 100%;
      width: 100%;
      filter: grayscale(1);
    }
    .title{
      font-size: 1.3rem;
      font-weight: 700;
      letter-spacing: .1rem;
  
    }
    .news-info{
      padding: .5rem 1rem;
      background: linear-gradient(230.75deg, #2c55be 0%, #2b4997 102.06%);
      bottom: -50%;
      .news-title{
        font-size: 1rem;
        font-weight: 700;
        color: white;
        margin: 0;
        display: none;
      }
      .news-desc{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        color: white;
        display: none;
      }
      .see-more{
        padding: .1rem .4rem;
        color: white;
        background: transparent;
        border: 2px solid white;
        border-radius: 2rem;
        // display: none;
      }
    }
    &:hover{
      .title{
        opacity: 0;
        visibility: hidden;
      }
      img{
        filter: none;
      }
      .news-info{
        bottom: 0;
      }
    }
  }
  
}