.home{
  .section-info {
    .heading {
      font-size: 2.5rem;
      svg {
        margin-right: 1.25rem;
      }
    }
  }
  
}
