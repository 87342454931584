.projects-section{
  padding: 4rem 3.75rem;
  font-family: "Outfit", sans-serif;
  .projects-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
    .project{
      opacity: 0;
      animation: fadeIn .5s ease-out forwards;
      img{
        width: 100%;
        border-radius: 2rem;
        transform: translateY(1.7rem);
      }
      .info{
        position: relative;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 4rem 0 0rem;
        background-color: #0B193C;
        color: white;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        min-height: 10rem;
        max-height: 10rem;
        .project-name{
          transition-duration: .5s;
        }
        .see-more-btn{
         
          color: #1B3884;
          background-color: white;
          padding: .5rem 1.5rem;
          border: none;
          border-radius: 3rem;
          transition-duration: .5s;
        }
      }
    }
  }
  .see-more{
    padding: 1rem 5rem;
    border-radius: 1rem;
    font-size: 2rem;
    color: #0B193C;
    border: 2px solid #1B3884;
  }
}

// Responsive
@media screen and (min-width: 992px) {
  .projects-section{
  
    .section-info{
      .heading{
      
      }
      .desc{
      
      }
    }
    .projects-container{
   
      .project{
      
        img{
        
        }
        .info{
      
          .see-more-btn{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #1B3884;
            background-color: white;
            padding: .5rem 1.5rem;
            border: none;
            border-radius: 3rem;
            // 
            opacity: 0;
          }
        }
        &:hover{
          .project-name{
            transform: translateY(-7rem);
            padding: 2rem 0;
            // background-color: #0B193C;
            background-image: linear-gradient((to top, #0B193C, #0B193C, #0B193C, transparent));
          }
          .see-more-btn{
            opacity: 1;
          }
        }
      }
    }
    .see-more{
      
    }
  }
}

@media screen and (max-width: 768px) {
  .projects-section{
    padding: 4rem 3.75rem;
    font-family: "Outfit", sans-serif;
    // 
    padding: 3.5rem 1.25rem;

    .section-info{
      .heading{
        // 
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .desc{
        // 
        font-size: 1rem;
        font-weight: 700;
      }
    }
    .projects-container{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 2rem;
      margin-bottom: 2rem;
      overflow: hidden;
      transition-duration: .5s;
      transition-timing-function: ease-in-out;
      // 
      display: flex;
      flex-direction: column;
      align-items: center;
      .project{
        opacity: 0;
        animation: fadeIn .5s ease-out forwards;
        // 
        width: 80%;
        img{
          width: 100%;
          border-radius: 2rem;
          transform: translateY(1.7rem);
          // 
        }
        .info{
          text-align: center;
          font-size: 1.2rem;
          font-weight: 700;
          padding: 3rem 0 1rem;
          background-color: #0B193C;
          color: white;
          border-bottom-left-radius: 2rem;
          border-bottom-right-radius: 2rem;
          min-height: 12rem;
          max-height: 12rem;
          .see-more-btn{
            color: #1B3884;
            background-color: white;
            padding: .5rem 1.5rem;
            border: none;
            border-radius: 3rem;
            // 
            
          }
        }
      }
    }
    .see-more{
      padding: 1rem 5rem;
      border-radius: 1rem;
      font-size: 2rem;
      color: #0B193C;
      border: 2px solid #1B3884;
      
      
    }
  }
  
}



// Keyframes

@keyframes fadeIn {
  from{
    opacity: 0;
    
  }
  to{
    opacity: 1;
  }
}