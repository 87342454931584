.news-body{
  padding: 5rem;
  .content-wrapper{
    width: 100%;
    display: flex;
    align-items: flex-start;
    .news-image{
    }
  }

  .news-title{
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(269.32deg, #0b193c 21.57%, #2241af 114.17%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .news-text{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }
  .news-image{
    margin-bottom: 4rem;
    max-width: 50%;
  }
}

//  Responsive:

@media screen and (max-width: 992px) {
  .news-body{
    padding: 2rem;
    overflow: hidden;

    .desc{
      font-size: 1rem;
      white-space: wrap;
    }
  
  } 
}