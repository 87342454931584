.scroll-header{
  position: fixed;
  transform: translateY(-100%);
  top: 0;
  left: 0;
  padding: 0 4rem ;
  color: white;
  background-color: #001f29;
  // background-image: linear-gradient(to bottom right,#001f29, #0b5e7a );
  background: linear-gradient(230.75deg, #2c55be 0%, #2b4997 102.06%);
  font-family: "Inter";
  z-index: 1000;
  transition-duration: 1s;
  .logo{
    display: flex;
    align-items: center;
    color: white;
      text-decoration: none;
    img{
      max-width: 14vw;
      min-width: 150px;
    }
    .logo-title{
      margin: 0;
      
    }
  }
  .nav {
    list-style: none;
    padding:2rem 0 ;
    .link {
      position: relative;
      padding: 0 1.3rem;
      
      a {
        font-size: 12px;
        text-decoration: none;
        color: white;
      }
      
      &:not(.contact-us)::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.3rem;
        content: "";
        width: 0;
        height: 0.2rem;
        background-color: white;
        border-radius: 1rem;
        transition-duration: .5s;
      }
      &.active {
        position: relative;
        a {
          font-weight: 900;
        }
        &::after{
          width: .5rem;
        }
      }
      &:hover::after{
        width: .5rem;
      }
    }
  }
  .contact-btn{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-image: linear-gradient(to right, #1480ff, #1b3884);
    border: 2px solid #597acf;
    border-radius: 50%;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
  &.arabic{
    .nav{
      .link{
        a{
          font-size: 1rem;

        }
      }
    }
  }
}