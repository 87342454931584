.testimonial {
  position: relative;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 224px;
  background-color: white;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  // 
  min-width: 460px;
  transition-duration: .5s;
  transition-timing-function: ease-out;
  .text {
    text-align: start;
    color: #1b3884;
  }
  img {
    width: 5rem;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2.5rem;
  }
}


// Responsive

@media screen and (max-width: 576px) {
.testimonial{
  min-width: 300px;
  padding: 1rem;
  .text{
    font-size: 1rem;
  }
}
}