// Global Rules
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-display: swap;
}

.App.arabic {
  * {
    font-family: "Tajawal";
  }
}

// Components
.heading {
  display: flex;
  align-items: center;
  margin-bottom: 4.75rem;
  font-size: 2.5rem;
  font-weight: 900;
  background: linear-gradient(269.32deg, #0b193c 21.57%, #2241af 114.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  svg {
    margin-right: 2.25rem;
  }
  &.gap-3 {
    svg {
      margin-right: 0;
    }
  }
}

.desc {
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 2.7rem;
}

// Landing:
.landing {
  position: relative;
  color: white;
  background-image: url("./Assets/Images/landingbg.webp");
  background-size: cover;
  height: 585px;
  font-family: "Outfit", sans-serif;
  padding: 0 4rem;
  overflow: hidden;
  .title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    z-index: 100;
    opacity: 0;
    animation: fadeIn 2s 0.3s ease-out forwards;
  }

  .desc {
    max-width: 65ch;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 5rem;
    z-index: 100;
    opacity: 0;
    animation: fadeIn 2s 0.6s ease-out forwards;
  }
  .landing-shape {
    position: absolute;
    z-index: 2;
    right: 5rem;
    opacity: 0;
    animation: fadeInRight 0.5s 1s forwards ease-in-out;
    width: fit-content;
  }
  .stars {
    position: absolute;
    right: 5rem;
    top: 155px;
  }
  &.arabic {
    direction: rtl;
    .landing-shape {
      right: inherit;
      left: 5rem;
    }
  }
}

// Fonts
// @font-face {
//   font-family: 'Inter';
//   src: url('./Assets/Fonts/Inter-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Outfit';
//   src: url('./Assets/Fonts/Outfit-Regular.ttf') format('truetype');
// }

/* outfit-regular - latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Outfit/outfit-v6-latin-regular.svg#Outfit") format("svg"); /* Legacy iOS */
}

/* outfit-700 - latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Assets/Fonts/Outfit/outfit-v6-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Outfit/outfit-v6-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Outfit/outfit-v6-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Outfit/outfit-v6-latin-700.svg#Outfit") format("svg"); /* Legacy iOS */
}
/* outfit-900 - latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Assets/Fonts/Outfit/outfit-v6-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Outfit/outfit-v6-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Outfit/outfit-v6-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-900.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Outfit/outfit-v6-latin-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Outfit/outfit-v6-latin-900.svg#Outfit") format("svg"); /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Assets/Fonts/Inter/inter-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Inter/inter-v12-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Inter/inter-v12-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Inter/inter-v12-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Assets/Fonts/Inter/inter-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Inter/inter-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Inter/inter-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Inter/inter-v12-latin-700.svg#Inter") format("svg"); /* Legacy iOS */
}

/* inter-900 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Assets/Fonts/Inter/inter-v12-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Inter/inter-v12-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Inter/inter-v12-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-900.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Inter/inter-v12-latin-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Inter/inter-v12-latin-900.svg#Inter") format("svg"); /* Legacy iOS */
}

/* tajawal-regular - arabic */
@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 400;
  src: url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-regular.svg#Tajawal") format("svg"); /* Legacy iOS */
}

/* tajawal-700 - arabic */
@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 700;
  src: url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-700.svg#Tajawal") format("svg"); /* Legacy iOS */
}

/* tajawal-900 - arabic */
@font-face {
  font-family: "Tajawal";
  font-style: normal;
  font-weight: 900;
  src: url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.woff") format("woff"),
    /* Modern Browsers */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./Assets/Fonts/Tajawal/tajawal-v9-arabic-900.svg#Tajawal") format("svg"); /* Legacy iOS */
}

// keyframes:

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

// Responsive

@media screen and (max-width: 768px) {
  // Landing:
  .landing {
    text-align: start;
    .landing-shape {
      filter: opacity(25%);
    }
  }
}

@media screen and (max-width: 600px) {
  .landing {
    text-align: start;
    padding: 0 10vw;
    height: 80vh;
    .title {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    .desc {
      font-size: 1rem;
      max-width: 30ch;
    }
  }
}

@media screen and (max-width: 400px) {
  .landing {
    text-align: start;
    padding: 0 5vw;

    .title {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
    .desc {
      font-size: 0.8rem;
      max-width: 35ch;
    }
  }
}
