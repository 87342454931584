.clients {
  text-align: center;
  min-height: 619px;
  padding: 154px 0 7rem 0;
  margin: 0px 50px;
  overflow: hidden;
  .section-info {
    margin-bottom: 2.25rem;
  }
  .client-image{
    justify-content: center;
    width: auto !important;
    
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 530px) {
  .clients {
    .section-info {
      .heading{
        font-size: 1.5rem;
      }
    }
  }
}