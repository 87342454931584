.products-section{
  padding: 4rem 0;
  font-family:  "Outfit", sans-serif;
  .section-info{
    padding: 0 3.75rem;
  }
  .products-container{
    position: relative;
    padding: 0 3.75rem;
    .products-group{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) ;
      gap: 1.25rem;
      margin-bottom: 2rem;
      .product{
        position: relative;
        padding: 0;
        margin-top: 2rem;
        transition-duration: 1s;
        // width: 453px;
        // height: 670px;
        img{
          width: 100%;
          border-radius: 1rem;
        }
        .product-icon{
          position:absolute;
          top: 15%;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition-duration: .3s;
          z-index: 2;
        }
        .product-info{
          min-height: 278px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          position: absolute;
          width: 100%;
          padding: 1rem 1.8rem 2rem;
          bottom: 0;
          color: white;
          background: rgba(88, 89, 91, 0.4);
          backdrop-filter: blur(29px);
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          transition-duration: .2s;
          
          .product-name{
            font-size: 1.5rem;
            font-weight: 800;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
          }
          .product-desc{
            position: relative;
            text-align: start;
            font-weight: 400;
            line-height: 1.7rem;
            margin-bottom: 2.1rem;
            height: 100px;
            overflow: hidden;
          }
          .see-more{
            font-size: 1.1rem;
            padding: 0.85rem 5rem;
            color: white;
            background-color: #0B193C;
            border: none;
            border-radius: .33rem;
            transition-duration: .3s;
          }
        }
        &:hover, &.active{
          .product-icon{
            opacity: 1;
          }
          .product-info{
            min-height: 100%;
            background: rgba(11, 25, 60, 0.5);
            border-radius: 1rem;
            .product-desc{
              overflow: visible;
              height: 100%;
            }
          }
          .see-more{
            color: #0B193C;
            background-color: white;
          }
        }
      }
    }
  }
  
}
.slick-slide>div {margin: 0 2rem;}

// Responsive:

@media screen and (max-width: 992px) {
  .products-section{
    padding: 4rem 0;
    font-family: "Outfit", sans-serif;
    .section-info{
      padding: 0 3.75rem;
    }
    .products-container{
      position: relative;
      padding: 0 3.75rem;
      .products-group{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) ;
        gap: 1.25rem;
        .product{
          position: relative;
          padding: 0;
          transition-duration: 1s;
          img{
            width: 100%;
            border-radius: 1rem;
          }
          .product-icon{
            position:absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition-duration: .3s;
            z-index: 2;
          }
          .product-info{
            min-height: 278px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            width: 100%;
            padding: 1rem 1.8rem 2rem;
            bottom: 0;
            color: white;
            background: rgba(88, 89, 91, 0.4);
            backdrop-filter: blur(29px);
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            transition-duration: .5s;
            
            .product-name{
              font-size: 1.5rem;
              font-weight: 800;
              margin-bottom: 1.5rem;
            }
            .product-desc{
              text-align: start;
              font-weight: 400;
              line-height: 1.7rem;
              margin-bottom: 2.1rem;
              height: 100px;
              overflow: hidden;
            }
            .see-more{
              font-size: 1.1rem;
              padding: 0.85rem 5rem;
              color: white;
              background-color: #0B193C;
              border: none;
              border-radius: .33rem;
              transition-duration: .3s;
            }
          }
          &:hover, &.active{
            .product-icon{
              opacity: 1;
            }
            .product-info{
              min-height: 100%;
              background: rgba(11, 25, 60, 0.5);
              border-radius: 1rem;
            }
            .see-more{
              color: #0B193C;
              background-color: white;
            }
          }
        }

      }
    }
    
  }
}


@media screen and (max-width: 768px) {
  .products-section{
    padding: 4rem 0;
    font-family: "Outfit", sans-serif;
    .section-info{
      padding: 0 3.75rem;
      // 
      margin-bottom: 3.5rem;
      .heading{
        // 
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      .desc{
        // 
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
      }
    }
    .products-container{
      position: relative;
      padding: 0 3.75rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) ;
      gap: 1.25rem;
      // 
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 3.25rem;

      .product{
        position: relative;
        padding: 0;
        transition-duration: 1s;
        margin: 0 3.75rem 0 3.75rem;
        img{
          width: 100% !important;
          border-radius: 1rem;
        }
        .product-icon{
          position:absolute;
          top: 10%;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition-duration: .3s;
          z-index: 2;
        }
        .product-info{
          min-height: 278px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          position: absolute;
          width: 100%;
          padding: 1rem 1.8rem 2rem;
          bottom: 0;
          color: white;
          background: rgba(88, 89, 91, 0.4);
          backdrop-filter: blur(29px);
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          transition-duration: .5s;
          // 
          min-height: 278px;
          .product-name{
            font-size: 1.5rem;
            font-weight: 800;
            margin-bottom: 1.5rem;
            // 
            text-align: center;
            font-size: 1rem;
            margin-bottom: .7rem;
          }
          .product-desc{
            text-align: start;
            font-weight: 400;
            line-height: 1.7rem;
            margin-bottom: 2.1rem;
            // 
            font-size: .8rem;
            margin-bottom: 1rem;
            height: 100px;
            overflow: hidden;
          }
          .see-more{
            font-size: 1.1rem;
            padding: 0.85rem 5rem;
            color: white;
            background-color: #0B193C;
            border: none;
            border-radius: .33rem;
            transition-duration: .3s;
            // 
            padding: 0.85rem 2rem;
          }
        }
        &:hover, &.active{
          .product-icon{
            opacity: 1;
          }
          .product-info{
            min-height: 100%;
            background: rgba(11, 25, 60, 0.5);
            border-radius: 1rem;
            .product-desc{
              overflow: visible;
              height: 100%;
            }
          }
          .see-more{
            color: #0B193C;
            background-color: white;
          }
        }
      }
    }
    
  }
}