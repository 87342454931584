

header.main-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 3rem 0 3rem;
  color: white;
  background-color: transparent;
  font-family: "Inter";
  z-index: 10000;
  .logo{
    display: flex;
    align-items: center;
    color: white;
      text-decoration: none;
    img{
    max-width: 17vw;
      min-width: 170px;
    }
    .logo-title{
      margin: 0;
      
    }
  }
  .bars{
    display: none;
  }
  .nav {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 3rem 0;
    transition: .5s ease-in-out;
    .link {
      position: relative;
      opacity: 0;
      height: fit-content;
      a {
        font-size: 12px;
        font-weight: 900;
        text-decoration: none;
        width: 100%;
        padding: 0 1.3rem;
        color: white;
      }
      
      &:not(.contact-us)::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.3rem;
        content: "";
        width: 0;
        height: 0.2rem;
        background-color: white;
        border-radius: 1rem;
        transition-duration: .5s;
      }
      &.active {
        position: relative;
        a {
          font-weight: 900;
        }
        &::after{
          width: .5rem;
        }
      }
      &:hover::after{
        width: .5rem;
      }
      &:last-child{
      }
    }
  }
  .contact-btn{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-image: linear-gradient(to right, #1480ff, #1b3884);
    border: 2px solid #597acf;
    border-radius: 50%;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: .5rem;
    }
    
  }
  &.arabic{
    .nav{
      .link{
        a{
          font-size: 1rem;

        }
      }
    }
  }
}


// Links Animations:
@for $i from 1 through 7 {
  .link:nth-child(#{$i}){
    animation: fadeInUp .3s ease-out forwards;
    animation-delay: #{$i / 4}s;
  }
}


// Keyframes:

@keyframes fadeInUp {
  0%{
    transform: translateY(-100px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


// Responsive:

@media screen and (max-width: 768px){
  header.main-header {
    padding: 2rem;
    // 
    // overflow: hidden;
    .logo{
    }
    .bars{
      display: block;
      cursor: pointer;
    }
    .nav {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      background-color: #1b3884;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 1rem 1rem;
      transform: translateX(100%);
      .link {
        margin-bottom: 1rem;
        a {
          font-size: 1.5rem;
          padding: 0rem 1.3rem;
        }
        
        &:not(.contact-us)::after {
          display: none;
        }
        &.active {
          position: relative;
          &::after{
            width: .5rem;
          }
        }
        &:hover::after{
          width: .5rem;
        }
        &:last-child{
          display: block;
        }
      }
    }
    .contact-us-btn{
      padding: 0.8rem 1.5rem;
      font-weight: 500;
      display: none;
    }
  }
  
}



@media screen and (max-width: 992px) {
  header.main-header {

    .logo{
      img{
      }
    }
    .nav {
      .link {
        a {
          // font-size: .9rem;
          padding: 0rem 1.3rem;
        }
        
        &:not(.contact-us)::after {
        }
        &.active {
          position: relative;
          &::after{
            width: .5rem;
          }
        }
        &:hover::after{
          width: .5rem;
        }
        
        
      }
    }
    .contact-us-btn{
      padding: 0.8rem 1rem;
      font-size: .8rem;
      font-weight: 500;
    }
  }
}



@media screen and (max-width: 380px) {
  header.main-header {
  padding: 2rem 1rem;

    .logo{
      font-size: .9rem;
    }
  }
  
  
}