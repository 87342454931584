.projectPageLanding {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  h1 {
    font-weight: 600;
    z-index: 10;
    color: white;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    background-color: #00000070;
  }
}

.projectPageContent > div {
  padding: 5rem 5vw;
  width: 100%;

  & img {
    max-width: 60rem;
    height: auto;
    border-radius: 1rem;
    margin: 0 auto;
    background-color: black;
  }
}
