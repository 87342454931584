

.solution-preview {
  padding: 2rem 0;
  margin: 6rem 0;
  display: block;
  animation: fadeInDown;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transition-duration: 1s;
  grid-column-start: 1;
  grid-column-end: 4;
  .solution-title {
    margin-bottom: 4rem;
  }
  
  .solution-image {
    position: relative;
    margin-bottom: 5rem;
    img {
      width: 100%;
      border-radius: 2rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      background-image: linear-gradient(to left, #4b61b0, transparent);
      border-radius: 2rem;
      opacity: 0.5;
    }
    .solution-desc {
      position: absolute;
      width: 40ch;
      top: 50%;
      transform: translateY(-50%);
      left: 2rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: white;
    }
    .slider-btns {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3rem;
      z-index: 2;
      .slider-btn {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: white;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .solution-branches {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 4rem;
    .solution-branch {
      position: relative;
      & *{
        z-index: 3;
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, #132B3C, transparent);
        border-radius: 2rem;
        z-index: 2;
      }
      img {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
      }
      .branch-name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: white;
        width: 20ch;
        transition: 0.5s;
        > button.download{
          display: none;
        }
      }
      .download-overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding-bottom: 1.5rem;
        width: 100%;
        // height: 520px;
        height: 100%;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #132b3c 100%);
        background-blend-mode: normal, multiply, hue, normal, normal;
        box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.15);
        opacity: 0;
        transition-duration: 0.5s;
        .name {
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.5rem;
          color: white;
          width: 20ch;
          margin-bottom: 2rem;
        }
        a.download {
          color: white;
          padding: 0.3rem 6rem;
          font-size: 1.5rem;
          font-weight: 700;
          background-color: transparent;
          border-radius: 2rem;
          border: 0.1rem solid white;
        }
      }
      &:hover {
        > .branch-name {
          opacity: 0;
        }
        .download-overlay {
          opacity: 1;
        }
      }
    }
  }
  &.arabic{
    .content{
      .solution-image{
        .solution-desc{
          left: initial;
          right: 2rem;
        }
        &::after{
          right: initial;
          left: 0;
          background-image: linear-gradient(to right, #4b61b0, transparent);

        }
      }
    }
  }
}

@keyframes fadeInDown {
  0%{
    transform: translateY(100px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}



@keyframes fadeOutDown {
  0%{
    transform: translateY(0px);
    opacity: 1;
  }
  100%{
    transform: translateY(100px);
    opacity: 0;
  }
}



// Responsive

@media screen and (max-width: 768px) {
  .solution-preview {
    padding: 2rem 0;
    // margin: 6rem 0;
    display: block;
    grid-column-start: 1;
    grid-column-end: 4;
    // 
    margin: 0 ;
    padding: 2rem;
    .solution-title {
      margin-bottom: 4rem;
      // 
      display: none;
    }
    
    .solution-image {
      position: relative;
      margin-bottom: 5rem;
      img {
        width: 100%;
        border-radius: 2rem;
        // 
        border-radius: 1rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 30%;
        background-image: linear-gradient(to left, #4b61b0, transparent);
        border-radius: 2rem;
        opacity: 0.5;
        // 
        border-radius: 1rem;
      }
      .solution-desc {
        position: absolute;
        width: 40ch;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
        font-size: 1.5rem;
        font-weight: 700;
        color: white; 
        // 
        font-size: 1.1rem;
      }
      .slider-btns {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3rem;
        z-index: 2;
        .slider-btn {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: white;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .desc{ 
      // 
      text-align: center;
      font-weight: 400;
      font-size: 1rem;
    }
      // 
      overflow: hidden;
      width: 100vw;
      .solution-branches {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 4rem;
        // 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 3rem;
        .solution-branch {
          position: relative;
          img {
            border-radius: 2rem;
            width: 100%;
            // 
          }
          .branch-name {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2rem;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.5rem;
            color: white;
            width: 20ch;
            transition: 0.5s;
            // 
            font-size: 1rem;
            button.download{
              color: white;
              padding: 0.3rem 3rem;
              font-size: 1rem;
              font-weight: 700;
              background-color: transparent;
              border-radius: 2rem;
              border: 0.1rem solid white;
              width: 100%;
              text-align: center; 
              // 
              display: block;
            }
          }
          .download-overlay {
            position: absolute;
            top: 0;
            left: 0;
            padding-bottom: 1.5rem;
            width: 100%;
            height: 520px;
            border-radius: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #132b3c 100%);
            background-blend-mode: normal, multiply, hue, normal, normal;
            box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.15);
            opacity: 0;
            transition-duration: 0.5s;
            // 
            display: none;

            .name {
              font-size: 1.5rem;
              font-weight: 700;
              line-height: 1.5rem;
              color: white;
              width: 20ch;
              margin-bottom: 2rem;
              // 
              display: none;
            }
            button.download {
              color: white;
              padding: 0.3rem 6rem;
              font-size: 1.5rem;
              font-weight: 700;
              background-color: transparent;
              border-radius: 2rem;
              border: 0.1rem solid white;
            }
          }
          &:hover {
            > .branch-name {
              opacity: 0;
              // 
              opacity: 1;
            }
            .download-overlay {
              opacity: 1;
            }
          }
        }
      }

    
  
  }
}

@media screen and (max-width: 600px) {
  .solution-preview {
   
    .solution-title {
      margin-bottom: 4rem;
      // 
      display: none;
    }
    
    .solution-image {
      img {
      }
      &::after {
      }
      .solution-desc {
        font-size: .6rem;
      }
      .slider-btns {
        display: none;
        .slider-btn {
        }
      }
    }
    .desc{ 
      // 
      text-align: center;
      font-weight: 400;
    }
      // 
      overflow: hidden;
      width: 100vw;
      .solution-branches {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 4rem;
        // 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 3rem;
        .solution-branch {
          position: relative;
          img {
            border-radius: 2rem;
            width: 100%;
            // 
          }
          .branch-name {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2rem;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.5rem;
            color: white;
            width: 20ch;
            transition: 0.5s;
            // 
            font-size: 1rem;
            button.download{
              color: white;
              padding: 0.3rem 3rem;
              font-size: 1rem;
              font-weight: 700;
              background-color: transparent;
              border-radius: 2rem;
              border: 0.1rem solid white;
              width: 100%;
              text-align: center;
            }
          }
          .download-overlay {
            position: absolute;
            top: 0;
            left: 0;
            padding-bottom: 1.5rem;
            width: 100%;
            height: 520px;
            border-radius: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #132b3c 100%);
            background-blend-mode: normal, multiply, hue, normal, normal;
            box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.15);
            opacity: 0;
            transition-duration: 0.5s;
            // 
            display: none;

            .name {
              font-size: 1.5rem;
              font-weight: 700;
              line-height: 1.5rem;
              color: white;
              width: 20ch;
              margin-bottom: 2rem;
              // 
              display: none;
            }
            button.download {
              color: white;
              padding: 0.3rem 6rem;
              font-size: 1.5rem;
              font-weight: 700;
              background-color: transparent;
              border-radius: 2rem;
              border: 0.1rem solid white;
            }
          }
          &:hover {
            > .branch-name {
              opacity: 0;
              // 
              opacity: 1;
            }
            .download-overlay {
              opacity: 1;
            }
          }
        }
      }

    
  
  }
}