.cm-word {
  position: relative;
  padding: 159px 5rem;
  min-height: 910px;
  background-color: #f4f4f4;
  z-index: 100;
  .heading {
    font-size: 2.5rem;
    margin-bottom: 4.6rem;
    svg {
      margin-right: 2.25rem;
    }
  }
  .sections {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -4rem;
    .section {
      text-align: center;
      padding: 3rem 5rem;
      color: #0b193c;
      background-color: white;
      font-size: 2rem;
      font-weight: 700;
      width: 20ch;
      &:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }
      &:last-child {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
      &.active {
        background-color: #0b193c;
        color: white;
      }
    }
  }
  .content-container {
    background-color: #0b193c;
    color: white;
    border-radius: 1rem;
    img {
      border-radius: 1rem 0 1rem 1rem;
      max-height: 100%;
      height: 100%;
    }
    .word {
      padding: 4rem;
      border-radius: 0 1rem 1rem 0;
      .text {
        font-size: 1.25rem;
        line-height: 2.5rem;
        max-width: 65ch;
      }
      .quoteMarkDown {
        right: -4rem;
        margin-top: 1.5rem;
        text-align: end;
        position: relative;
      }
      .name {
        // position: absolute;
        // bottom: 4rem;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }
  &.arabic {
    direction: rtl;
    .heading {
      gap: 1rem;
    }
    .content-container {
      img {
        border-radius: 0 1rem 1rem 0;
      }
      .quoteMark {
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

// Responsive

@media screen and (max-width: 992px) {
  .cm-word {
    position: relative;
    padding: 159px 5rem;
    height: 910px;
    background-color: #ebebeb;
    z-index: 100;
    //
    height: initial;
    .heading {
      font-size: 2.5rem;
      margin-bottom: 4.6rem;
      svg {
        margin-right: 2.25rem;
      }
    }
    .sections {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -4rem;
      //
      top: 2rem;
      display: none;
      .section {
        text-align: center;
        padding: 3rem 5rem;
        color: #0b193c;
        background-color: white;
        font-size: 2rem;
        font-weight: 700;
        width: 20ch;
        //
        text-align: center;
        font-size: 1rem;
        max-width: 15ch;
        padding: 1rem 0.7rem;
        &.active {
          background-color: #0b193c;
          color: white;
          //
          background-color: #1b3884;
        }
      }
    }
    .heading {
      //
      display: flex;
      justify-content: center;
      background: none;
      -webkit-text-fill-color: #1b3884;
      font-size: 2rem;
      svg {
        //
        margin-right: 1rem;
      }
    }
    .content-container {
      background-color: #0b193c;
      color: white;
      border-radius: 1rem;
      //
      background-color: #1b3884;
      img {
        border-radius: 1rem 0 0 1rem;
        //
        display: none;
      }
      .word {
        padding: 4rem;
        border-radius: 0 1rem 1rem 0;
        //
        position: relative;
        width: 100%;
        .text {
          font-size: 1.25rem;
          line-height: 2.5rem;
          width: 65ch;
          //
          // font-size: 1rem;
          width: 100%;
        }
        .quoteMark {
          position: absolute;
          &.quoteMarkUp {
            top: -1.5rem;
            left: 1.7rem;
          }
          &.quoteMarkDown {
            position: relative;
            margin-top: 1.5rem;
            text-align: end;
            //
            position: absolute;
            text-align: start;
            bottom: -2rem;
            right: 1.7rem;
          }
        }
        .name {
          // position: absolute;
          // bottom: 4rem;
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .cm-word {
    position: relative;
    padding: 159px 5rem;
    height: 910px;
    background-color: #ebebeb;
    z-index: 100;
    //
    height: initial;
    //
    padding: 159px 1.5rem;
    .heading {
      font-size: 2.5rem;
      margin-bottom: 4.6rem;
      svg {
        margin-right: 2.25rem;
      }
    }
    .sections {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -4rem;
      //
      top: 2rem;
      display: none;
      .section {
        text-align: center;
        padding: 3rem 5rem;
        color: #0b193c;
        background-color: white;
        font-size: 2rem;
        font-weight: 700;
        width: 20ch;
        //
        text-align: center;
        font-size: 1rem;
        max-width: 15ch;
        padding: 1rem 0.7rem;
        &.active {
          background-color: #0b193c;
          color: white;
          //
          background-color: #1b3884;
        }
      }
    }
    .heading {
      //
      display: flex;
      justify-content: center;
      background: none;
      -webkit-text-fill-color: #1b3884;
      font-size: 2rem;
      svg {
        //
        margin-right: 1rem;
      }
    }
    .content-container {
      background-color: #0b193c;
      color: white;
      border-radius: 1rem;
      //
      background-color: #1b3884;
      flex-direction: column;
      img {
        border-radius: 1rem;
        //
        display: block;
        width: 50% !important;
        margin-top: 2rem;
        margin-right: auto;
        margin-left: auto;
        border-width: 0.3rem;
        border-color: #0b193c;
        border-style: solid;
        margin-bottom: 4rem;
      }
      .word {
        padding: 4rem;
        border-radius: 0 1rem 1rem 0;
        //
        position: relative;
        width: 100%;
        //
        padding: 4rem 1.5rem;
        .text {
          font-size: 1.25rem;
          line-height: 2.5rem;
          width: 65ch;
          //
          // font-size: 1rem;
          width: 100%;
          //
          font-size: 1rem;
          line-height: 2rem;
          font-weight: 500;
        }
        .quoteMark {
          position: absolute;
          &.quoteMarkUp {
            top: -1.5rem;
            left: 1.7rem;
          }
          &.quoteMarkDown {
            position: relative;
            margin-top: 1.5rem;
            text-align: end;
            //
            position: absolute;
            text-align: start;
            bottom: -2rem;
            right: 1.7rem;
          }
        }
        .name {
          // position: absolute;
          // bottom: 4rem;
          font-size: 2rem;
          font-weight: 900;
        }
      }
    }
  }
}
