.testimonials {
  background-color: #f0ecec;
  padding: 7rem 4rem 5rem;
  // height: 558px;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
    .slick-list{
      padding-top: 60px !important;
      padding-bottom: 100px !important;
    }
    .slick-current{
      .testimonial{
        background-color: #1b3884;
        transform: scale(110%);
        z-index: 2;
        .text {
          color: white;
          font-size: 1.1rem;
        }
        img {
          width: 6rem;
          transition-duration: .5s;
        }
      }
    }
  
 
 
}



// Responsive:

@media screen and (max-width: 576px) {
  .testimonials {
    .heading{
      margin-right: 0rem;
    }
  
  }
}
