.certificates{
  padding: 0 6rem;
  .heading{
    width: 100%;
    padding: 6rem;
    color: white;
    background-clip: initial;
    -webkit-text-fill-color: initial;
    background-color: #1B3884;
    background-image: none;
    box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.2);
  }
  .certificates-container{
    position: relative;
    bottom: 8rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px ,1fr));
    gap: 3rem; 
    user-select: none;

    .certificate{
      position: relative;
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all .3s;
      cursor: pointer;
      &:hover{transform: scale(110%);}
      &:first-child{
      }
      img{
        width: 17rem;
      }
      .certificate-name{
        text-align: center;
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.125rem;
        span{
          font-size: .875rem;
        }
      }
      
    }
  }
  .certificate-preview{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    opacity: 0;
    animation: fadeIn .1s ease forwards;
    img{
      height: 90%;
      z-index: 2;
    }
  }
}


// Responsive:

@media screen and (max-width: 768px) {
  .certificates{
    padding: 0 6rem;
    // 
    padding: 0;
    overflow: hidden;
    // overflow: visible;
    .heading{
      width: 100%;
      padding: 6rem 3rem;
      color: white;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      background-color: #1B3884;
      background-image: none;
      box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.2);
      // 
      
    }
    .certificates-container{
      position: relative;
      bottom: 8rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px ,1fr));
      gap: 3rem;
      // 
      display: flex;
      justify-content: flex-start;
      gap: 0;
      // left: 10vw;
      transform: translateX(0);
      // pointer-events: none;
      pointer-events: all;
      overflow: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        display: none;
      }
      .certificate{
        position: relative;
        filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all .3s;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 4rem;
        }
        &:hover{transform: none;}
       
        .certificate-name{
          text-align: center;
          margin-top: 2rem;
          font-weight: 700;
          font-size: 1.125rem;
          span{
            font-size: .875rem;
          }
        }
        
      }
    }
    .certificate-preview{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      opacity: 0;
      animation: fadeIn .1s ease forwards;
      img{
        height: 50%;
        z-index: 2;
      }
    }
  }
  
}

@media screen and (max-width: 576px) {

}


// Animation
@keyframes fadeIn {
  from{
    opacity: 0;
  }to{
    opacity: 1;
  }
}


