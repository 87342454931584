form{
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 3rem;
  input, textarea{
    padding: .3rem 1.5rem;
    margin-bottom: 2.5rem;
    font-weight: 700;
    border-radius: 1.75rem;
    border: none;
    &:focus{outline: none;}
    &::placeholder{opacity: .50;}
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
  }
  textarea{
    grid-column: 1 / 3;
    grid-row-start: 3;
    grid-row-end: 6;
    resize:none;
  }
  .submit-btn{
    padding: .5rem 4rem;
    background-color: #1B3884;
    color: white;
    font-size: 1.25rem;
    border: none;
    border-radius: 2rem;
    justify-self: center;
    align-self: center;
    grid-column: 1 / 3;
    // cursor: pointer;
  }
  &.arabic{
    direction: rtl;
    input, textarea{
      direction: rtl;
    }
  }
}

// Responsive

@media screen and (max-width: 768px) {
  form{
    height: 100%;
    max-width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, 1fr);
    column-gap: 3rem;
    // 
    column-gap: 2rem;
    input, textarea{
      padding: .3rem 1.5rem;
      margin-bottom: 2.5rem;
      font-weight: 700;
      border-radius: 1.75rem;
      border: none;
      // 
      padding: .5rem ;
      font-weight: 500;
      width: 100%;
      &:focus{outline: none;}
      &::placeholder{opacity: .50;}
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
    }
    textarea{
      grid-column: 1 / 3;
      grid-row-start: 3;
      grid-row-end: 6;
      resize:none;
    }
    .submit-btn{
      padding: .5rem 4rem;
      background-color: #1B3884;
      color: white;
      font-size: 1.25rem;
      border: none;
      border-radius: 2rem;
      justify-self: center;
      align-self: center;
      grid-column: 1 / 3;
    }
  }
}