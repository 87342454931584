.contact{
  display: flex;
  padding: 5rem;
  background: #F0ECEC;
  font-family: "Outfit", sans-serif;
  .collage{
    img{
      width: 100%;
    }
  }
  .form{
    .section-info{
      margin-bottom: 4rem;
      .heading{
        font-size: 2rem;
      }

    }
    
  }
}



// Responsive:



@media screen and (max-width: 992px) {
  .contact{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    .collage{
      display: none;
    }
    .form{
      .section-info{
        margin-bottom: 4rem;
        .heading{
          font-size: 2rem;
          display: flex;
          justify-content: center;
        }
      }
      input, textarea{
        padding: .8rem 1.5rem;

      }
      
    }
  }
}


@media screen and (max-width: 768px) {
  .contact{
    padding: 2rem 1rem;
   .form{
    .section-info{

      .heading{
        font-size: 1.5rem;
      }
      svg{
        margin: 0;
        display: none;
      }
    }
    .contact-form{
     padding: 5rem 3rem;
    }
   }
  }
}



@media screen and (max-width: 380px) {
  .contact{
    padding: 5rem 2rem;
   .form{
    .section-info{

      .heading{
        font-size: 1.5rem;
      }
      svg{

      }
    }
    .contact-form{
     padding: 5rem 1.5rem;
     input, textarea{
      padding: .8rem 1.5rem;
      font-size: 1rem;

    }
    }
   }
  }
}